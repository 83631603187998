import * as React from 'react'
import { Facebook, Instagram, LinkedIn, Twitter } from '@material-ui/icons'
import {
  createStyles,
  Grid, Icon,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import SEO from '../components/SEO'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import PortableText from 'react-portable-text'
import Book from '../model/book'
import { Variant } from '@material-ui/core/styles/createTypography'
import Author from '../model/author'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    authorDetailsContainer: {
      width: '60%',
      margin: 'auto',
      paddingTop: 20,
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    authorName: {
      fontFamily: theme.typography.body2.fontFamily,
      fontWeight: "bold",
      textAlign: 'center',
      paddingBottom: 20
    },
    authorImage: {
      width: '40%',
      borderRadius: 30
    },
    bioBlock: {
      paddingTop: 20,
      textAlign: 'justify',
    },
    bioSerializer: {
      paddingBottom: 20,
    },
    narrative: {
      fontFamily: 'yrsa, serif',
    },
    booksGrid: {
      width: '100%',
    },
    bookItem: {
      paddingTop: 10,
    },
    bookLink: {
      textDecoration: 'none',
    },
    socialMedia: {
      display: 'inline',
      listStyle: 'none',
      margin: 'auto',
      paddingTop: 20
    },
    socialMediaLink: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
    socialMediaItem: {
      display: 'inline',
      paddingRight: 20
    },
  }),
)

const AuthorPage = ({ data }: any) => {
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  const author = data.author as Author

  const AuthorBooks = (_) => {
    const gridItems = author.authorBooks.map((book: Book) => {
      return (
        <Grid item xs={12} sm={4} className={classes.bookItem}>
          <Link to={`/books/${book.slug.current}`} className={classes.bookLink}>
            <GatsbyImage
              image={book.frontCover.asset.gatsbyImageData}
              alt={`${book.title} front cover`}
              objectFit='contain'
            />
            <Typography variant='body2' color='primary'>{book.title}</Typography>
          </Link>
        </Grid>
      )
    })

    return (
      <Grid container className={classes.booksGrid}
            alignContent='space-between' alignItems='flex-start'>
        {gridItems}
      </Grid>
    )
  }

  interface SocialMediaIconProps {
    service: string
  }

  const SocialMediaIcon = ({ service }: SocialMediaIconProps) => {
    let icon
    switch (service) {
      case 'Facebook':
        icon = <Facebook fontSize='large' />
        break
      case 'Instagram':
        icon = <Instagram fontSize='large' />
        break
      case 'LinkedIn':
        icon = <LinkedIn fontSize='large' />
        break
      case 'Twitter':
        icon = <Twitter fontSize='large' />
        break
      default:
        icon = null
    }

    return icon
  }

  const AuthorSocialMedia = (_) => {
    const links = author.socialMedia.map((socialMedia: SocialMedia) => {
      return (
        socialMedia.account ?
          <li key={socialMedia.service} className={classes.socialMediaItem}>
            <a target='_blank' href={socialMedia.socialMediaUrl} className={classes.socialMediaLink}>
              <SocialMediaIcon service={socialMedia.service} />
            </a>
          </li>
          : null
      )
    })

    return <ul className={classes.socialMedia}>{links}</ul>
  }

  const descVariant: Variant = matches ? 'h5' : 'h6'

  const AuthorBio = (_) => {
    const serializers = {
      normal: (props) => (
        <Typography variant={descVariant} className={classes.bioSerializer}>
          <span className={classes.narrative}>{props.children}</span>
        </Typography>
      ),
    }

    return (
      <PortableText
        content={author._rawBio}
        serializers={serializers}
        className={classes.bioBlock}
      />
    )
  }

  return (
    <>
      <SEO title={author.name} />
      <Grid
        container
        direction='column'
        className={classes.authorDetailsContainer}
      >
        <Typography variant='h2' className={classes.authorName} color='primary'>
          {author.name}
        </Typography>
        <GatsbyImage
          image={author.authorImage.asset.gatsbyImageData}
          alt={`${author.name} photo`}
          className={classes.authorImage}
          objectFit='contain'
        />
        <AuthorBio />
        <AuthorBooks />
        <AuthorSocialMedia />
      </Grid>
    </>
  )
}

export const query = graphql`
  query($authorId: String!) {
    author: sanityAuthor(id: { eq: $authorId }) {
      id
      name
      _rawBio
      socialMedia {
        service
        account
        socialMediaUrl
      }
      authorImage {
        asset {
          gatsbyImageData(
            fit: FILLMAX
            width: 800
            height: 800
            placeholder: BLURRED
          )
        }
      }
      authorBooks {
        id
        title
        slug {
          current
        }
        frontCover {
          asset {
            gatsbyImageData(
              fit: FILLMAX
              width: 200
              height: 200
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
`

export default AuthorPage
